<script>
    import {closingVideoModal, openVideoModal, videoEmbedCode} from "../stores/InformaAppStore.js";
    import {onMount} from "svelte";
    import {gsap} from "gsap";
    import * as focusTrap from 'focus-trap';

    let modalFocusTrap;
    let videoIframe;

    let tl = gsap.timeline({
        paused: true
    })

    $: openModal($openVideoModal)

    let openModal = value => {
        tl.reversed(!tl.reversed());
        if(modalFocusTrap !== undefined) {
            // Need to wait for the modal to animate in before activating the focus trap
            setTimeout(() => {
                value ? modalFocusTrap.activate() : modalFocusTrap.deactivate();
            }, 1000)
        }
    }

    onMount(() => {

        modalFocusTrap = focusTrap.createFocusTrap('.video-modal__content', {
            initialFocus: () => document.querySelector('.video-modal__content'),
            clickOutsideDeactivates: true,
            onDeactivate: () => {
                // closeModal()
            }
        });

        let openModalAnimation = () => {

            tl.to('.video-modal', {
                opacity: 1,
                display: 'block',
                duration: 0.35
            }).to('.video-container', {
                opacity: 1,
                duration: 0.35
            }).to('.video-modal__close-btn', {
                scale: 1,
                duration: 0.35
            }).reverse();
        }

        openModalAnimation();
    })

    function closeModal() {
        openVideoModal.set(false)
        closingVideoModal.set(true)

        setTimeout(() => {
            videoEmbedCode.set('')
            closingVideoModal.set(false)
        }, 500)
    }

</script>

<div on:click={closeModal} aria-hidden="{!$openVideoModal}" class="video-modal {$openVideoModal ? 'video-modal--open' : ''}"
     aria-describedby="videoModalDescription">

    <div class="video-modal__content">
        <div class="sr-only" id="videoModalDescription">
            This is a dialog window which overlays the main content of the page.
        </div>
        <div class="w-full relative">
            <button class="video-modal__close-btn" title='Close video story modal' on:click={closeModal}>
                <svg class="h-32 w-32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="currentColor"
                          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"/>
                </svg>
            </button>
            <div class="video-container">
                <div class="video-container__inner">
                    <iframe bind:this={videoIframe} title="" src="{$videoEmbedCode ? `https://player.vimeo.com/video/${$videoEmbedCode}`: ''}"
                            width="640" height="360"
                            frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen></iframe>
                </div>
            </div>
            <!-- Needed for accessiblity tabbing to work for the trap focus -->
            <a href="#"></a>
        </div>
    </div>
</div>

<style lang="scss">

  .video-modal {
    background-color: var(--color-modal-bg);
    display: none;
    opacity: 0;
    @apply absolute inset-0 z-20 p-8;
    position: fixed;

    @screen sm {
      @apply p-64;
    }

    &__content {
      @apply h-full w-full relative flex items-center;
    }

    &__close-btn {
      transform: scale(0);

      background-color: unset;
      border: unset;
      border-radius: unset;
      display: unset;
      padding: unset;
      margin-bottom: unset;
      width: unset;

      @apply absolute -top-48;

      &:hover {
        transform: scale(1.2);
      }

      @screen sm {
        @apply top-0 left-0;
      }
    }

  }

  .video-container {
    opacity: 0;
    border-radius: 0.25rem;
    margin: auto;
    @apply container;
    @screen sm {
      @apply px-48;
    }
  }

  .video-container__inner {
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 0.25rem;
    background: white;
  }

  .video-container__inner::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  .video-container__inner iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>