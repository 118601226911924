<script>
    import Header from '$lib/components/Header.svelte';
    import '../app.css';
    import VideoModal from "$lib/components/VideoModal.svelte";
</script>

<svelte:head>
    <link rel="icon" type="image/x-icon" href="/favicon.ico">
</svelte:head>

<Header/>
<main class="main-wrapper">
    <slot/>
</main>
<VideoModal/>

<style lang="scss">
  .main-wrapper {
    position: relative;
    height: 100%;
  }
</style>
