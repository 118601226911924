<script>
    import {onMount} from "svelte";
    import {gsap} from "gsap";
    import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
    import {eventStore, showFullHeader} from "../stores/InformaAppStore.js";

    let showFullHeaderBool;
    let mobileMenuVisible = false;
    let toggleMenu;

    showFullHeader.subscribe(value => {
        showFullHeaderBool = value;
    });

    let tl = gsap.timeline({
        paused: true
    })
    let mm = gsap.matchMedia();

    $: eventData = $eventStore?.payload?.data;

    onMount(() => {

        let openNavAnimation = () => {
            let mobileHeaderMenu = document.querySelector('.header-nav');
            let closeMenuBtn = document.getElementById('mobilenav-btn-close')
            let openMenuBtn = document.getElementById('mobilenav-btn-open')

            tl.to("#main-header", {
                height: mobileMenuVisible ? 'auto' : window.innerHeight + 34,
                duration: 0.5,
                ease: "power2.out",
                onComplete: args => {
                    mobileHeaderMenu.style.display = "block"
                },
                onReverseComplete: args => {
                    mobileHeaderMenu.style.display = "none";
                    mobileMenuVisible = false;
                }
            })
                .to(openMenuBtn, {
                    y: -31
                }, "<")
                .to(closeMenuBtn, {
                    y: -31
                }, "<")
                .to(mobileHeaderMenu, {
                    opacity: 1,
                    duration: 0.35,
                    ease: "power2.out",
                    onComplete: args => {
                        mobileMenuVisible = true;
                    },
                }).reverse();
        }

        mm.add("(max-width: 767px)", () => {
            openNavAnimation();
        });

        setTimeout(() => {
            setupMenuEvents();
        }, 1500)

        toggleMenu = (event) => {
            tl.reversed(!tl.reversed());
        }

        // TODO: This is a hack to get jobs button to work on navigate back from the cookie policy page
        window.addEventListener('splashIntroMounted', () => {
            setTimeout(() => {
                setupMenuEvents();
            }, 1500)
        })

    })

    let findParentButton = (element, parentTagName) => {
        parentTagName = parentTagName.toUpperCase();
        let parentNode = element.parentNode;

        while (parentNode) {
            if (parentNode.tagName === parentTagName) {
                return parentNode;
            }
            parentNode = parentNode.parentNode;
        }

        return null;
    }

    let setupMenuEvents = () => {

        let links = document.querySelectorAll('.js-nav-link');

        links.forEach((link) => {

            link.addEventListener('click', (e) => {

                e.preventDefault;

                let targetSectionLabel = e.target.dataset.section;

                try {
                    if (e.target.dataset.section === undefined) {
                        let parentButton = findParentButton(e.target, 'button');
                        if (parentButton === null) return;
                        targetSectionLabel = parentButton.dataset.section;
                    }

                    let targetSection = document.getElementById(targetSectionLabel);

                animateToSection(targetSection, targetSectionLabel);
                } catch (e) {
                    console.log(e)
                }

            })
        });

        let accessiblitySectionLinks = document.querySelectorAll('.js-access-section-link')

        accessiblitySectionLinks.forEach((link) => {

            link.addEventListener('focus', (e) => {

                let targetSectionLabel = e.target.dataset.section;

                if (e.target.dataset.section === undefined) {
                    let parentButton = findParentButton(e.target, 'button');
                    targetSectionLabel = parentButton.dataset.section;
                }

                let targetSection = document.getElementById(targetSectionLabel);

                animateToSection(targetSection, targetSectionLabel);

                setTimeout(() => {
                    e.target.blur();
                }, 1000)

            })
        });

    }

    let animateToSection = function (targetSection, targetSectionLabel) {

        if(!targetSection) return;

        let linkST = ScrollTrigger.create({
            trigger: targetSection,
            invalidateOnRefresh: true,
            start: "top top"
        });

        let scrollTo = linkST.start;

        if (mobileMenuVisible) {
            tl.reversed(!tl.reversed());
        }

        switch (targetSectionLabel) {
            case 'informa-ingredients':
            case 'informa-benefits':
                scrollTo += 1
                break;
            case 'splash-intro-section':
                scrollTo = 0
                break;
            case 'informa-careers':
            case 'social-media':
            case 'grow-with-us':

                if (window.innerWidth <= 768) {
                    scrollTo = scrollTo
                } else {
                    scrollTo = scrollTo + window.innerHeight
                }

                break;
            case 'informa-graduates':
                scrollTo = scrollTo + window.innerHeight + (window.innerHeight)
                break;
            case 'introduction':
                scrollTo = scrollTo + window.innerHeight
            default:
                scrollTo = scrollTo
        }

        mm.add("(min-width: 767px)", () => {

            gsap.to(window, {
                duration: 1,
                scrollTo: scrollTo,
                overwrite: "auto"
            });

            return () => { // optional
            };
        });

        mm.add("(max-width: 768px)", () => {

            setTimeout(() => {
                gsap.to(window, {
                    duration: 1,
                    scrollTo: scrollTo,
                    overwrite: "auto"
                });
            }, 1000)

            return () => { // optional
            };
        });
    }


</script>

<header id="main-header" aria-label="Site Header"
        class="main-header {showFullHeaderBool ? 'main-header--full-header' : ''} relative">
    <div id="parent-site-bar" class="parent-site-bar">
        <div class="parent-site-bar__inner">
            <a href="https://www.informa.com/" target="_blank" class="flex items-center space-x-16 ">
                <img src="/assets/images/informa-logo.svg" alt="Informa" class="w-[64px] md:w-[80px] lg:w-[100px]">
                <div class="text-12px lg:text-14px leading-[12px] lg:leading-[14px] md:pt-[3px] lg:pt-[5px]">Return to
                    main site
                </div>
            </a>
        </div>
    </div>
    <div class="main-header__menu-wrapper">
        <!-- Container -->
        <div class="container m-auto flex flex-col sm:flex-row sm:justify-center gap-48 py-[12px] sm:py-4">

            <div class="flex w-full sm:w-auto justify-between items-center">
                <!-- logo -->
                <a href="/" class="js-nav-link focus:ring text-14px btn-logo plain" role="button" data-section="splash-intro-section"
                        aria-label="Top of page">
                    <span class="sr-only">Home</span>
                    <img src="/assets/images/logo-life-at-informa--white.svg" alt="Life at informa logo"
                         class="w-[120px] sm:w-[140px] md:w-[220px]">
                </a>


                <!-- Mobile menu button -->
                <div class="overflow-hidden h-[30px] flex flex-col items-center">
                    <button id="mobilenav-btn-open" on:click={toggleMenu}
                            class="btn-reset sm:hidden text-white bg-cobalt block" aria-label="Open Mobile Menu">
                        <span class="sr-only">Open Mobile Menu</span>

                        <svg width="41px" height="30px" viewBox="0 0 41 30" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"
                               stroke-linecap="round">
                                <g id="Artboard" transform="translate(-2492.000000, -1587.000000)" stroke="currentColor"
                                   stroke-width="3">
                                    <g id="Group-2" transform="translate(2492.000000, 1587.000000)">
                                        <g id="Group" transform="translate(2.000000, 4.000000)">
                                            <line x1="0" y1="10.645" x2="37" y2="10.645" id="mobile-btn-line-2"></line>
                                            <line x1="0" y1="0.5" x2="37" y2="0.5" id="mobile-btn-line-1"></line>
                                            <line x1="0" y1="20.79" x2="37" y2="20.79" id="mobile-btn-line-3"></line>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                    <div class="h-4"></div>
                    <button id="mobilenav-btn-close" on:click={toggleMenu}
                            class="btn-reset sm:hidden text-white bg-cobalt flex flex-row bg-coral p-32"
                            aria-label="Close Mobile Menu">
                        <span class="sr-only">Close Mobile Menu</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30.406" height="30.406"
                             viewBox="0 0 30.406 30.406">
                            <g id="Group_577" data-name="Group 577" transform="translate(-306.297 -10.297)">
                                <line id="Line_61" data-name="Line 61" x2="37"
                                      transform="translate(308.419 12.419) rotate(45)" fill="none" stroke="currentColor"
                                      stroke-linecap="round" stroke-width="3"/>
                                <line id="Line_62" data-name="Line 62" x2="37"
                                      transform="translate(308.418 38.581) rotate(-45)" fill="none"
                                      stroke="currentColor" stroke-linecap="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                </div>
            </div>
            <!-- nav -->
            <nav aria-label="Site Nav" class="header-nav">
                <ul class="header-nav__list">
                    <li>
                        <a href="/#informa-stories" class="contrast header-nav__link js-nav-link" data-section="informa-stories" role="button">
                            Stories
                        </a>
                    </li>
                    <li>
                        <a href="/#informa-ingredients" class="contrast header-nav__link js-nav-link" data-section="informa-ingredients" role="button">
                            Ingredients
                        </a>
                    </li>
                    <li>
                        <a href="/#informa-benefits" class="contrast header-nav__link js-nav-link" data-section="informa-benefits" role="button">
                            Benefits
                        </a>
                    </li>
                    <li>
                        <a href="/#informa-careers" class="contrast header-nav__link js-nav-link" data-section="informa-careers" role="button">
                            Careers
                        </a>
                    </li>

                    <li>
                        <a href="/#contact-us" class="contrast header-nav__link js-nav-link" data-section="contact-us" role="button">
                            Contact us
                        </a>
                    </li>
                </ul>
            </nav>
        </div>

    </div>
</header>

<div class="main-header--full-header"></div>

<style lang="scss">

  .parent-site-bar {

    @apply p-4 bg-white;

    &__inner {
      @apply container m-auto px-16;
    }

    a {

      &:hover, &:focus {

        outline: none;

        div {
          text-decoration: 2px underline var(--color-indigo);
        }
      }
    }
  }

  .main-header {
    background-color: var(--color-indigo);

    @apply fixed w-full z-20;

    transform: translateY(-30px);
    transition: ease-in-out all 300ms;

    @screen md {
      transform: translateY(-40px);
    }

    @screen lg {
      transform: translateY(-41px);
    }

    &__menu-wrapper {
      @apply mx-auto px-16;
    }

    &--full-header {
      transform: translateY(0);
    }

    &--open-menu {
      height: calc(100% + 31px);
    }
  }

  .header-nav {
    display: none;
    opacity: 0;
    padding: var(--space-sm);
    height: 100%;

    @screen sm {
      display: block;
      position: relative;
      opacity: 1;
      background-color: transparent;
    }

    &__list {
      @apply flex flex-col items-center gap-48;
      @screen sm {
        @apply flex-row gap-24 md:gap-32;
      }
    }

    &__link {
      @apply text-20px font-opensans-regular;
      padding: var(--space-xxs) var(--space-sm);
      --background-color: var(--color-white);
      --color: var(--color-indigo);
      box-shadow: none;
      outline: none;
      border: none;
      margin-bottom: 0;
      white-space: nowrap;

      @screen sm {
        @apply text-14px;
      }
    }

    &__mobile-close {
      @apply flex justify-between items-center text-white mb-80;
      @screen sm {
        @apply hidden;
      }
    }

    &--open-menu {
      display: block;
      transform: translateY(31px);
    }
  }
</style>

